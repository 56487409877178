import { createActionGroup, props } from '@ngrx/store';
import { DealerModel, DealerRequestModel } from '../../_models/dealer.model';

export const DealersActions = createActionGroup({
  source: 'Dealers',
  events: {
    'Load Dealers': props<{ filters: any }>(),
    'Load Dealers Success': props<{
      items: ReadonlyArray<DealerModel>;
      total: number;
    }>(),
    'Load Dealer': props<{ id: string }>(),
    'Load Dealer Success': props<{ data: Readonly<DealerModel> }>(),
    'Add Dealer': props<{ data: Readonly<DealerRequestModel> }>(),
    'Add Dealer Success': props<{ data: Readonly<DealerModel> }>(),
    'Edit Dealer': props<{ id: string; data: Readonly<DealerRequestModel> }>(),
    'Edit Dealer Success': props<{ id: string; data: Readonly<DealerModel> }>(),
    'Remove Dealer': props<{ id: string }>(),
    'Remove Dealer Success': props<{ id: string }>(),
  },
});
