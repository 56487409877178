import { createSelector, createFeatureSelector } from '@ngrx/store';
import { DealersState } from '../_reducers/dealers.reducer';
import { DealerModel } from '../../_models/dealer.model';

export const selectDealersState = createFeatureSelector<DealersState>('dealers');

export const currentDealer = createSelector(
  selectDealersState,
  (dealers: DealersState) => new DealerModel(dealers.selected)
);

export const currentDealersList = createSelector(
  selectDealersState,
  (dealers: DealersState) =>
    dealers.list.data.map((dealer) => new DealerModel(dealer))
);

export const currentDealersTotal = createSelector(
  selectDealersState,
  (dealers: DealersState) => dealers.list.total
);

export const currentDealersFilters = createSelector(
  selectDealersState,
  (dealers: DealersState) => dealers.list.filters
);
